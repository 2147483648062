import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AppointmentFacade, DamageFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { ParagraphComponent } from "@cg/core/ui";
import { Appointment, DamageChipCount, RequiredService } from "@cg/shared";
import { CarBringingScheduleText } from "./interfaces/car-bringing-schedule-text.component.interface";
import { CarServiceScheduleInfo } from "./interfaces/car-service-schedule-info.interface";

@Component({
  selector: "cg-car-bringing-schedule-text",
  templateUrl: "./car-bringing-schedule-text.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ParagraphComponent]
})
export class CarBringingScheduleTextTextComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public carBringingScheduleText: CarBringingScheduleText;

  private startTimesAreEqual: boolean;

  public startTimes: {
    customerAppointmentFrom: string;
    serviceCenterOpenFrom: string;
  };

  public constructor(
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.appointmentFacade.appointmentId$,
      this.appointmentFacade.availableAppointments$,
      this.appointmentFacade.isCalibration$,
      this.damageFacade.damageChipCount$,
      this.damageFacade.requiredService$
    ])
      .pipe(
        map(
          ([appointmentId, availableAppointments, isCalibration, damageChipCount, requiredService]: [
            string,
            Appointment[],
            boolean,
            DamageChipCount,
            RequiredService
          ]) => ({
            appointmentId,
            availableAppointments,
            requiresCalibration: isCalibration,
            damageChipCount,
            requiredService
          })
        ),
        filter(
          (carServiceScheduleInfo: CarServiceScheduleInfo) =>
            !!carServiceScheduleInfo.appointmentId && !!carServiceScheduleInfo.availableAppointments
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((carServiceScheduleInfo: CarServiceScheduleInfo) => {
        const appointment = carServiceScheduleInfo.availableAppointments.find(
          (app: Appointment) => app.appointmentId === carServiceScheduleInfo.appointmentId
        );

        if (!appointment) {
          return;
        }

        this.carBringingScheduleText = this.returnText(
          carServiceScheduleInfo.damageChipCount,
          carServiceScheduleInfo.requiredService,
          appointment.availabilityPeriodStart,
          appointment.customerAppointmentStart,
          carServiceScheduleInfo.requiresCalibration
        );
        this.cdr.detectChanges();
      });
  }

  private returnText(
    damageChipCount: DamageChipCount,
    requiredService: string,
    bringTimeStart: string,
    bringTimeEnd: string,
    isCalibration: boolean
  ): CarBringingScheduleText {
    this.initStartTimes(bringTimeStart, bringTimeEnd);
    let translationString = "";

    if (requiredService === RequiredService.REPAIR) {
      translationString = this.mapDamageChipCountTime(damageChipCount);
    } else if (requiredService === RequiredService.REPLACE && isCalibration === true) {
      translationString = "carBringingScheduleText.replace.replaceWithAdas";
    } else if (
      requiredService === RequiredService.REPLACE &&
      isCalibration === false &&
      this.startTimesAreEqual === true
    ) {
      translationString = "carBringingScheduleText.replace.replaceWithoutAdas.equalStarttime";
    } else if (
      requiredService === RequiredService.REPLACE &&
      isCalibration === false &&
      this.startTimesAreEqual === false
    ) {
      translationString = "carBringingScheduleText.replace.replaceWithoutAdas.differentStarttime";
    }
    return {
      content: translationString,
      customerAppointmentFrom: this.startTimes?.customerAppointmentFrom,
      serviceCenterOpenFrom: this.startTimes?.serviceCenterOpenFrom
    };
  }

  private initStartTimes(bringTimeStart: string, bringTimeEnd: string): void {
    this.startTimes = {
      serviceCenterOpenFrom: bringTimeStart ? this.returnTime(bringTimeStart) : "",
      customerAppointmentFrom: bringTimeEnd ? this.returnTime(bringTimeEnd) : ""
    };

    this.startTimesAreEqual = this.startTimes.customerAppointmentFrom === this.startTimes.serviceCenterOpenFrom;
  }

  private returnTime(dateString: string): string {
    const date = new Date(dateString);
    return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
  }

  private mapDamageChipCountTime(damageChipCount: DamageChipCount) {
    switch (damageChipCount) {
      case DamageChipCount.ONE:
        return "carBringingScheduleText.repair.repairChipCount1";
      case DamageChipCount.TWO:
        return "carBringingScheduleText.repair.repairChipCount2";
      case DamageChipCount.THREE:
        return "carBringingScheduleText.repair.repairChipCount3";

      default:
        return "carBringingScheduleText.repair.repairChipCount1";
    }
  }
}
